import React from "react"
import { Benefit, Main, Row, Text } from "../../../components/atomics"
import Page from "../../../components/Pages/Page"
import colors from "../../../colors"
import { useState } from "react"
import { useEffect } from "react"
import { getQueryParam } from "../../../utils/query"
import { formatToMoney } from "../../../utils/cupom"
const benefits = [
  {
    title: "Acesso a Versão Web!",
    description: "Tenha acesso ao Flynow também pelo computador!",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/web01-pt.png",
    },
  },
  {
    title: "Acesso a Extensão do Navegador!",
    description:
      "Visualize facilmente todas as suas tarefas do dia, quando estiver usando o navegador (Chrome ou Firefox)!",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/extension01-pt.png",
    },
  },
  {
    title: "Widgets!",
    description:
      "Visualize todas suas tarefas do dia sem abrir o aplicativo, direto pela tela inicial do celular.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/widget-pt.png",
    },
  },
  {
    title: "+5 Modos de Visualização",
    description:
      "3 modos de agenda (3 dias, 7 dias, Mensal), Linha do Tempo e Pendências",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/modeView01-pt.png",
    },
  },
  {
    title: "Sem anúncios!",
    description: "Acesse o app sem interrupções, sem anúncios!",
  },
  {
    title: "Uso ilimitado!",
    description:
      "Sem limites de quantidade tarefas, hábitos e metas em andamento!",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/limitActivities01-pt.png",
    },
  },
  {
    title: "Notificações personalizadas!",
    description:
      "Tenha a posibilidade de escolher um som de alarme ou outro som diferente do padrão dos sistema para notificações.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/customNotification01-pt.png",
    },
  },
  {
    title: "Concluir tarefa pela notificação!",
    description:
      "Marque suas tarefas como concluída diretamente pelas notificações!",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/notificationDone-pt-light.png",
    },
  },
  {
    title: "Relatórios Semanais!",
    description:
      "Receba semanalmente relatórios sobre o seu desempenho nesse período",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/reportWeekly01-pt.png",
    },
  },
  {
    title: "Relatórios Mensais!",
    description:
      "Receba mensalmente relatórios sobre o seu desempenho nesse período",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/reportMonthly01-pt.png",
    },
  },

  {
    title: "Notas sobre atividades!",
    description:
      "Adicione notas em dias específicos em suas tarefas, hábitos e metas.",
    img: { uri: "https://flynow.s3.amazonaws.com/premium/note01-pt.png" },
  },
  {
    title: "Temporizador",
    description: "Adicionar temporizador em suas tarefas, hábitos e objetivos.",
    img: { uri: "https://flynow.s3.amazonaws.com/premium/timer01-pt.png" },
  },
  {
    title: "Cronômetro",
    description: "Adicionar cronômetro em suas tarefas, hábitos e objetivos.",
    img: { uri: "https://flynow.s3.amazonaws.com/premium/stopwatch01-pt.png" },
  },
  {
    title: "Roda da Vida!",
    description:
      "Permite classificar suas tarefas, hábitos e metas em categorias e visualizar o gráfico de sua roda da vida!",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/categories01-pt.png",
    },
  },
  {
    title: "Integração com Calendário!",
    description:
      "Permite importar eventos do calendário, exportar tarefas e hábitos para o calendário, e manter ambos sincronizados!",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/syncCalendar01-pt.png",
    },
  },
  {
    title: "Multiplas Notificações!",
    description:
      "Permite adicionar mais de uma notificação em suas tarefas e hábitos!",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/multipleNotifications01-pt.png",
    },
  },
  // {
  //   title: "Check-List dentro de Tarefas!",
  //   description:
  //     "Crie check-list dentro de suas tarefas e tenha mais controle sobre elas!",
  //   img: {
  //     uri: "https://flynow.s3.amazonaws.com/premium/taskcheckList-pt-light.png",
  //   },
  // },
  {
    title: "E muito mais...",
    description:
      "Tenha acesso á todas as futuras funcionalidades do aplicativo!",
  },
  // {
  //   title: 'Garantia de 7 dias!',
  //   img: {
  //     uri: 'https://flynow.s3.amazonaws.com/premium/garantia.png',
  //   },
  //   description:
  //     'Se, por qualquer motivo, você não gostar da versão premium, te devolvemos todo o seu dinheiro!',
  // }
]

const plans = [
  {
    label: "12 MESES",
    price: 79.99,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/3csbKveWJ7y58Fi144?locale=pt-BR",
      },
      {
        key: "PIX",
        description:
          "Clique no link abaixo, para pagar com o PIX. Você pode pagar de 3 formas: Ler o QR Code, Copiar o código do QR Code, ou com a Chave Pix.",
        titleButton: "Pagar com PIX",
        url: "https://nubank.com.br/pagar/uwbk/9I1AnxVyy9",
        FLYN20: "https://nubank.com.br/cobrar/uwbk/668ed33c-48c9-421b-8a4a-896e42accba5"
      },
    ],
  },
  // {
  //   label: "36 MESES",
  //   price: 159.99,
  //   data: [
  //     {
  //       key: "CARTÃO, MERCADO PAGO OU PAYPAL",
  //       description: 'Clique no link abaixo, para pagar com o Mercado Pago. Pelo mercado pago, você pode pagar com o seu saldo do mercado pago, paypal, pix ou cartão.',
  //       titleButton: 'Pagar com Mercado Pago',
  //       url: "https://mpago.la/1j6eR2P",
  //     },
  //     {
  //       key: "PIX",
  //       description: 'Clique no link abaixo, copie o código do QR Code, abra o app do seu banco, busque a opção Pix Copie e Cola e cole o código copiado para pagar.',
  //       titleButton: 'Pagar com PIX',
  //       url: "https://nubank.com.br/pagar/uwbk/gzZRy9Ni46",
  //     },
  //     {
  //       key: "TRANSFERÊNCIA BANCÁRIA",
  //       description: 'Agência: 0001 | Conta: 1441568-2 | Banco: Nubank | Titular: Rogerd | Valor: 159.99',
  //     },
  //   ],
  // },
  {
    label: "VITALÍCIO",
    price: 199.90,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/cN29CncOBdWt08M7sJ?locale=pt-BR",
      },
      {
        key: "PIX",
        description:
          "Clique no link abaixo, copie o código do QR Code, abra o app do seu banco, busque a opção Pix Copie e Cola e cole o código copiado para pagar.",
        titleButton: "Pagar com PIX",
        url: "https://nubank.com.br/cobrar/uwbk/66a24111-5dfb-472c-a8f5-f21f1d576915",
        FLYN20: "https://nubank.com.br/cobrar/uwbk/66a2407a-2637-4305-a547-b0916652cca1"
      },
    ],
  },
]

function PremiumPromotion() {
  const [plan, setPlan] = useState(plans[0])
  const [pay, setPay] = useState(plans[0].data[0])
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState("")

  useEffect(() => {
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  const getUrlCheckout = url => {
    if(cupom){
      if (url.includes("stripe")) {
        return url + "&prefilled_promo_code=" + cupom
      }
      if (url.includes("nubank")) {
        if(pay[cupom]) return pay[cupom]
        return null
      }
    }
    return url
  }

  function getPrices(basePrice) {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = basePrice
    return {
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      discontPercentage,
    }
  }
  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    return {
      URL_CHECKOUT: getUrlCheckout(pay.url),
      textCupom: cupom
        ? discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null
        : null,
      discontPercentage,
      ...getPrices(plan.price),
    }
  }

  const {
    URL_CHECKOUT,
    textCupom,
    discontPercentage,
  } = getInfo()

  return (
    <Page
      title="Premium"
      titlePage="Premium"
      pageId="premium"
      description={
        "Torne-se Premium e tenha acesso a ferramentas exclusivas! Com o plano premium, você tem acesso a diversas ferramentas para te auxiliar a ser mais produtivo, manter seus hábitos e atingir seus objetivos!"
      }
    >
      <Main>
        <div>
          <h1>Torne-se Premium e tenha acesso a ferramentas exclusivas!</h1>

          <Text style={{ marginTop: 10 }}>
            Com o plano premium, você tem acesso a diversas ferramentas para te
            auxiliar a ser mais produtivo, manter seus hábitos e atingir seus
            objetivos! <br />
            <br />
          </Text>

          {/* <br /> */}

          {textCupom && (
            <h4 style={{ color: "red", textAlign: "center", marginBottom: 20 }}>
              {textCupom}
            </h4>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Benefícios premium</h3>
            {benefits.map(i => (
              <Benefit>
                <Text
                  style={{
                    fontSize: "0.9rem",
                    fontFamily: "Poppins-Bold",
                    lineHeight: "1.1rem",
                  }}
                >
                  {i.title}
                </Text>
                <Text
                  style={{
                    fontSize: "0.9rem",
                    lineHeight: "1.1rem",
                    marginBottom: 5,
                  }}
                >
                  {i.description}
                </Text>
                {i.img && <img src={i.img.uri} height="150" />}
              </Benefit>
            ))}
          </div>

          <Text
            style={{ fontSize: "0.9rem", fontFamily: "Poppins-Bold", marginBottom: 10 }}
          >
            *Informações para adquirir o premium:
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Escolha o plano que deseja assinar e o método de pagamento.
            Realize o pagamento conforme as intruções para o pagamento do método
            escolhido.
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Se tiver qualquer dúvida, entre em contato com a gente!{" "}
          </Text>

          <br />
          <h4>Selecione o plano:</h4>
          <Row>
            {plans.map(i => (
              <div
                onClick={() => {
                  setPlan(i)
                  setPay(i.data[0])
                }}
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: 10,
                  marginRight: 10,
                  marginTop: 5,
                  border:
                    i.label === plan.label
                      ? `3px solid ${colors.primary}`
                      : "1px solid #222",
                  color: i.label === plan.label ? colors.primary : undefined,
                }}
              >
                <h4>{i.label}</h4>
                {discontPercentage ? (
                  <s>
                    <h3
                      style={{
                        fontSize: discontPercentage ? 20 : 28,
                        textAlign: "center",
                      }}
                    >
                      {i.price}
                    </h3>
                  </s>
                ) : (
                  <h3>{i.price}</h3>
                )}
                {discontPercentage >0 && (
                  <h3 style={{ fontSize: 28 }}>
                    {getPrices(i.price).newPrice}
                  </h3>
                )}
              </div>
            ))}
          </Row>
          <h4 style={{ marginTop: 15 }}>Selecione o método de pagamento:</h4>
          {plan.data.map(i => (
            <div
              onClick={() => setPay(i)}
              style={{
                cursor: "pointer",
                padding: "5px 10px",
                borderRadius: 10,
                marginRight: 10,
                marginTop: 5,
                border:
                  i.key === pay.key
                    ? `2px solid ${colors.primary}`
                    : "1px solid #222",
                color: i.key === pay.key ? colors.primary : undefined,
              }}
            >
              <Text style={{ fontSize: "0.9rem", lineHeight: "1.1rem" }}>
                {i.key}
              </Text>
            </div>
          ))}

          <h4 style={{ marginTop: 15 }}>Instruções para o pagamento:</h4>
          <div style={{ marginTop: 5 }}>
            <Text>
              {pay.description && (
                <Text
                  style={{
                    fontSize: "0.9rem",
                    lineHeight: "1.1rem",
                    marginBottom: 10,
                  }}
                >
                  {pay.description}
                </Text>
              )}
              {pay.url && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 30px",
                      backgroundColor: colors.green,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={URL_CHECKOUT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pay.titleButton}
                    </a>
                  </div>
                </div>
              )}
              {pay.obs && <h6>{pay.obs}</h6>}
              {pay.key === "PIX" && (
                <>
                  <Text
                    style={{
                      fontSize: "0.9rem",
                      lineHeight: "1.1rem",
                      marginTop: 10,
                    }}
                  >
                    Após o pagamento, nos envie o comprovante por Email ou
                    WhatsApp e informe o seu email cadastrado no app para a
                    liberação dos recursos premium para a sua conta.
                  </Text>
                </>
              )}
            </Text>
          </div>

          {pay.key === "PIX" && (
            <>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                Enviar comprovante via:
              </h4>
              <Text center style={{ fontSize: "0.95rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={
                        "https://api.whatsapp.com/send/?phone=5531999107753"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp
                    </a>
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={"mailto:productivity@appflynow.com"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email
                    </a>
                  </div>
                  <Text style={{ fontSize: "0.9rem" }}>
                    productivity@appflynow.com
                  </Text>
                </div>
              </Text>
            </>
          )}
        </div>
      </Main>
    </Page>
  )
}

export default PremiumPromotion
